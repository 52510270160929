import { Messages } from "../../lib/locale";

export const Swedish: Messages = {
  name: "Svenska",
  helpTitle: "Så här spelar du",
  help1: `Varje dag finns det ett nytt mysterieland. Ditt mål är att gissa mysterielandet med minst antal gissningar. Varje felaktig gissning kommer att dyka upp på jordklotet med en färg som visar hur nära den är mysterielandet. Ju <b>varmare</b> färgen är, desto närmare svaret är du.`,
  help2: `Till exempel, om mysterielandet är <b>Japan</b>, då skulle följande länder visas med dessa färger om man gissade:`,
  help3: `Ett nytt mysterieland är tillgängligt varje dag!`,
  France: "Frankrike",
  Nepal: "Nepal",
  Mongolia: "Mongoliet",
  "South Korea": "Sydkorea",
  Aux1: `<b>["Klicka", "Tryck"]</b> på globen för att spela!`,
  Aux2: "Har du en fråga?",
  Aux3: "Läs vår FAQ",
  Footer1: "av The Abe Train",
  Footer2: "Gillar du spelet?",
  Footer3: "Bjud mig på en kaffe",
  Loading: "Laddar...",
  FAQTitle: "FAQ",
  q1: "1. Hur beräknas avståndet mellan svaret och min gissning?",
  a1: "Avstånd mellan länder definieras som det minsta avståndet mellan deras gränser längs jordens yta.",
  q2: "2. Hur kan jag spela spelet om jag är färgblind eller synskadad?",
  a2: "Ett läge för färgblinda med hög kontrast kan aktiveras i <button>Inställningar</button>.",
  q3: "3. Hur avgör spelet vad som är ett giltigt land?",
  a3: "Globle använder detta <a>ramverk</a> för att avgöra vad som är en giltig gissning.",
  q4: "4. Är autonoma men inte suveräna länder med i spelet?",
  a4: "Vissa territorier kommer att visas i en neutral färg när deras suveräna land gissas, t.ex. Grönland för Danmark. Placeringen av dessa territorier påverkar inte färgen på det suveräna landet. De flesta små territorier visas inte i spelet, t.ex. Curaçao .",
  q5: "5. Jag hittade dagens mystiska land! När får jag spela igen?",
  a5: "Det mystiska landet ändras och dina gissningar återställs vid midnatt i din tidszon.",
  q6: "6. Är alternativa stavningar för länder acceptabla?",
  a6: "Det finns många länder med flera acceptabla namn. Vissa alternativa stavningar och tidigare namn accepteras, t.ex. Burma för Myanmar. Dessutom är akronymer acceptabla för vissa länder med flera ord, t.ex. UAE för Förenade Arabemiraten.",
  q7: "7. Ett land saknas eller en gräns är felaktig. Vad kan jag göra åt det?",
  a7: "Geografi kan vara ett känsligt ämne, och vissa länders gränser är ifrågasatta. Om du anser att en korrigering bör göras, vänligen ta upp ett ärende på {GitHub} eller skicka ett DM till mig på {Twitter}.",
  GameTitle: "Spel",
  Game1: "Skriv namnet på landet här",
  Game2: "Gissa",
  Game3: "Skriv namnet på vilket land som helst för din första gissning.",
  Game4: `Drag, <span>["klicka", "tryck"]</span>, och zooma in på globen för att hjälpa dig hitta nästa gissning.`,
  Game5: "Ogiltig gissning",
  Game6: "Landet redan gissat",
  Game7: "Mysterielandet är {answer}!",
  Game8: "Närmsta gräns",
  StatsTitle: "Statistik",
  Stats1: "Senaste vinst",
  Stats2: "Dagens gissningar",
  Stats3: "Spel vunna",
  Stats4: "Nuvarande streak",
  Stats5: "Max streak",
  Stats6: "Genomsnittliga gissningar",
  Stats7: "Genomsnittliga gissningar",
  Stats8: "Återställ",
  Stats9: "Dela",
  Stats10: "Är du säker på att du vill radera din poäng?",
  Stats11: "Statistik raderad.",
  Stats12: "Kopierad till urklipp!",
  SettingsTitle: "Inställningar",
  Settings1: "Dagtema",
  Settings2: "Nattema",
  Settings3: "Läge för färgblinda på",
  Settings4: "Läge för färgblinda av",
  Settings5: "Länder",
  Settings6: "Städer",
  Settings7: "Språk",
  Settings8: "Globle: Cities Edition kommer snart!",
  Settings9: "Öva",
  Settings10: "Regnbåge på",
  Settings11: "Regnbåge av",
  Answer: "Svar",
  Closest: "Närmaste",
  PracticeMode: "Du är nu i övningsläge",
  PracticeExit: "Stäng av övningsläge",
  PracticeNew: "Nytt övningsspel",
  Guessed: "gissade",
  SortByGuesses: "Sortera efter gissningsordning",
  SortByDistance: "Sortera efter avstånd",
};
